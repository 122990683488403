import axios from "axios";

// Additional export for the API PDF URL
//  const urlPdf = "https://dev.api.marion-materiaux.com"; // Dev
const urlPdf = "https://recette.api.marion-materiaux.com"; // Recette
// const urlPdf = "https://api.marion-materiaux.com"; // Prod

const instance = axios.create({
//  baseURL: "https://dev.api.marion-materiaux.com/api", // Dev
baseURL: "https://recette.api.marion-materiaux.com/api", // Recette
// baseURL: "https://api.marion-materiaux.com/api", // Prod
});

//key google maps
const REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyCoMoDDmlBQA3i8TOLYp4_NBVBrSr8Dj-c"

const authToken = localStorage.getItem("authToken");
const parsedUserData = JSON.parse(localStorage.getItem("userData"));

export const isTokenValid = (token) => {
  // Implement token validation logic here
  try {
    const parsedToken = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return parsedToken.exp > currentTime; // Check if token expiration is in the future
  } catch (e) {
    return false; // If there's an error, the token is invalid
  }
};

// checkTokenAndRedirect(authToken);

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { urlPdf , REACT_APP_GOOGLE_MAPS_API_KEY};

export default instance;
